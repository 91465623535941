@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .expand {
    @apply transition-transform duration-300 ease-in-out transform;
  }

  .expand:hover {
    @apply scale-105;
  }
}

@layer components {
  .loader {
    width: 50px;
    height: 50px;
    border: 8px solid #ddd;
    border-top: 8px solid #2196f3;
    border-bottom: 8px solid #2196f3;
    border-radius: 50%;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
