.suggestions {
  margin-top: 20px;
  line-height: 1.6;
}

.suggestions h2 {
  margin-top: 20px;
  font-size: 1.5em;
  font-weight: bold;
}

.suggestions p {
  margin-top: 10px;
}

.suggestions ul,
.suggestions ol {
  margin-top: 10px;
  margin-left: 20px;
  padding-left: 20px;
}

.suggestions li {
  margin-top: 10px;
}

.suggestions strong {
  font-weight: bold;
}

.suggestions em {
  font-style: italic;
}

.typical-container p {
  margin: 16px 0; /* Adjust margin for spacing between paragraphs */
}
